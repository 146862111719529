import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Επικοινωνία" />
    <p className="lh-lg bg-dark text-light text-center py-5">
      <h1>Επικοινώνησε μαζί μας</h1>
      <br />
      <h4>
        Η ομάδα εξυπηρέτησης της <span class="badge bg-secondary"><span className="text-dark">Cy</span><span className="text-warning">net</span><span className="text-dark">ix</span></span> είναι εδώ για να σε βοηθήσει!
      </h4>
    </p>

    <div className="d-flex justify-content-evenly flex-wrap mt-5">
      <div className="card shadow quickFix">
        <h2 className="text-center text-info m-4">
          <svg
            width="2em"
            height="2em"
            viewBox="0 0 16 16"
            className="bi bi-telephone-inbound"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            />
          </svg>
        </h2>

        <div className="card-body text-center px-5">
          <h5 className="font-weight-bolder py-2">Τηλέφωνα Επικοινωνίας</h5>
          <p>+30 210 5452572</p>
          <p>+30 210 5696201</p>
        </div>
      </div>

      <div className="card shadow quickFix">
        <h2 className="text-center text-info m-4">
          <svg
            width="2em"
            height="2em"
            viewBox="0 0 16 16"
            className="bi bi-envelope"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
            />
          </svg>
        </h2>
        <div className="card-body text-center px-5">
          <h5 className="font-weight-bolder py-2">E-mail Επικοινωνίας</h5>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td colSpan="2">info@cynetix.gr</td>
              </tr>
              <tr>
                <td>Support</td>
                <td>support@cynetix.gr</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <span className="badge bg-info d-block w-75 mt-5 mx-auto text-wrap biggerBadge">
      Το ωράριο λειτουργίας μας είναι Δευτέρα με Παρασκευή από τις 9:00 μέχρι τις 17:00
    </span>

    <p className="lh-lg bg-dark text-light text-center my-5 py-5">
      <h1>Που θα μας βρείτε</h1>
      <br />
      <h4>
        <svg
          width="3em"
          height="3em"
          viewBox="0 0 16 16"
          className="bi bi-geo-alt"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
          />
          <path
            fillRule="evenodd"
            d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
          />
        </svg>
      </h4>
    </p>

    <div className="mt-n5">
      <iframe
        className="mapFrame d-block w-100"
        height="480"
        frameBorder="10"
        title="Google Maps"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDSja1WGWX9r1HjSn4C-5OaIGbunDcnI0U
                  &q=Cynetix,Περικλέους 25+Αιγάλεω"
        allowFullScreen
      ></iframe>
    </div>
    <p className="font-italic">* Κερνάμε τον καφέ!</p>
  </Layout>
)

export default Contact
